<template>
    <div>
        <NavPackage/>
        <div v-if="packageById != null" class="checkout-page">
          <div class="container-checkout display-webs">
              <div class="row">
                  <div class="col-md-6 col-pad col-sm-12 payment-section">
                      <h2>Checkout</h2>
                      <div v-if="!isLogin">
                          <p class="descrip default-text-style">You need  to have a Jaybod account in order to complete your purchase</p>
                          <p class="descrip default-text-style"><b-button v-b-modal.modal-signin-v3 class="btn-modal">Use an existing account</b-button> or <b-button v-b-modal.modal-signup-v3 class="btn-modal">signup for free</b-button></p><br>
                      </div>
                      <ModalLogin/>
                      <ModalRegister/>
                      <ModalConfirmed/>
                      <ModalVerify/>
                      <p class="default-text-style"><strong>Billing Cycle</strong></p>
                      <div v-if="packageById.is_enterprise == true" class="row">
                          <div class="col-md-6">
                              <input type="radio" checked id="two" :value="true" v-model="prices">
                              <label for="two">
                                  <p>
                                      Billed Monthly<br>
                                      ${{packageById.price_month}}/month
                                  </p>
                              </label>
                          </div>
                      </div>
                      <div v-else class="row">
                          <div class="col-md-6">
                              <input type="radio" id="one" :value="false" v-model="prices">
                              <label for="one">
                                  <p>
                                      Billed Yearly<br>
                                      ${{packageById.price_year}}/year
                                  </p>
                              </label>
                          </div>
                          <div class="col-md-6">
                              <input type="radio" checked id="two" :value="true" v-model="prices">
                              <label for="two">
                                  <p>
                                      Billed Monthly<br>
                                      ${{packageById.price_month}}/month
                                  </p>
                              </label>
                          </div>
                      </div><br><br>
                      <p class="default-text-style"><strong>Payment Method</strong></p>
                      <div class="row">
                          <div class="col-md-6 col-sm-12">
                              <input type="radio" id="pay" checked="true" class="mt-2" value="stripe">
                              <label for="pay">
                                  <img src="@/assets/images/logo-visa.svg" alt="">
                              </label><br>
                          </div>
                      </div><br>
                      <div class="purchase-btn-1">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="termCondition"
                            name="checkbox-1"
                            class="checkbox-terms"
                            value="accepted"
                            unchecked-value="not_accepted">
                            <p class="term-text default-text-style">I have read and agree to the <span><a href="https://jaybod.com/terms-service" class="text-blue">Terms and Policy.</a></span></p>
                        </b-form-checkbox><br>
                        <div class="d-flex justify-content-start align-items-center">
                            <button :disabled="termCondition == 'not_accepted' ? termCondition : !termCondition" :class="termCondition == 'accepted' ? 'active btn base-btn' : 'btn base-btn'" @click="purchasePackage">
                            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>                     
                            <span class="default-text-style"><span :class="termCondition == 'accepted' ? 'text-white' : ''">Purchase</span></span>
                            </button>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-1 linespack">

                  </div>
                  <div v-if="packageById.is_enterprise == true" class="col-md-5 col-pad col-sm-12 summary-section">
                      <h2>Plan Summary</h2>
                      <div class="d-flex justify-content-between">
                          <p class="default-text-style"><strong>{{packageById.name_package}}</strong></p>
                          <p class="default-text-style"><a href="/packages">Change plan</a></p>
                      </div>
                      <div class="prices">
                          <p class="card-text">${{prices ? packageById.price_month : packageById.price_year}}<span> /{{prices ? 'month' : 'year'}}</span></p>
                      </div>
                      <div v-if="!showMore">
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Free for up to {{packageById.free_user}} seats, then ${{packageById.price_user}}/seat/month</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Unlimited workgroups</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Unlimited workgroup participants</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=true">Show more</p>
                      </div>
                      <div v-if="showMore">
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Free for up to {{packageById.free_user}} seats, then ${{packageById.price_user}}/seat/month</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Unlimited workgroups</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Unlimited workgroup participants</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Storage sharing and control</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Admin dashboard</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Customized logo and colors</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Custom domain</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=false">Show less</p>
                      </div>
                      <div class="purchases">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">{{packageById.name_package}}</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                          <hr class="horizontal-line">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">Total</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                      </div>
                  </div>
                  <div v-else class="col-md-5 col-pad col-sm-12 summary-section">
                      <h2>Plan Summary</h2>
                      <div class="d-flex justify-content-between">
                          <p class="default-text-style"><strong>{{packageById.name_package}}</strong></p>
                          <p class="default-text-style"><a href="/packages">Change plan</a></p>
                      </div>
                      <div class="prices">
                          <p class="card-text">${{prices ? packageById.price_month : packageById.price_year}}<span> /{{prices ? 'month' : 'year'}}</span></p>
                      </div>
                      <div v-if="!showMore">
                        <p><span class="title-feature default-text-style"><strong>Core Feature</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.storage)}} storage</strong></p>
                        <p class="default-text-style" v-if="packageById.basic.max_upload.toLowerCase() === 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{upperCase(packageById.basic.max_upload)}} file size per upload</strong></p>
                        <p class="default-text-style" v-if="packageById.basic.max_upload.toLowerCase() !== 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.max_upload)}} maximum file size per upload</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Secure file sharing</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=true">Show more</p>
                      </div>
                      <div v-if="showMore">
                        <p><span class="title-feature default-text-style"><strong>Core Feature</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.storage)}} storage</strong></p>
                        <p class="default-text-style" v-if="packageById.basic.max_upload.toLowerCase() === 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{upperCase(packageById.basic.max_upload)}} file size per upload</strong></p>
                        <p class="default-text-style" v-if="packageById.basic.max_upload.toLowerCase() !== 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.max_upload)}} maximum file size per upload</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Secure file sharing</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Office document editor</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Anytime, anywhere access</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>File recovery</strong></p>
                        <p><span class="title-feature default-text-style"><strong>Workgroup</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.workgroup.max_workgroup}} maximum workgroup</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.workgroup.max_participants}} participants</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=false">Show less</p>
                      </div>
                      <div class="purchases">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">{{packageById.name_package}}</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                          <hr class="horizontal-line">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">Total</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <div class="container-checkout-mobile display-mobile">
              <div class="row">
                  <div class="col-md-12 col-pad payment-section">
                      <h2 class="title-mobile">Checkout</h2>
                      <div v-if="!isLogin">
                          <p class="descrip default-text-style">You need  to have a Jaybod account in order to complete your purchase</p><br>
                          <p class="descrip default-text-style"><b-button v-b-modal.modal-signin class="btn-modal">Use an existing account</b-button> or <b-button v-b-modal.modal-signup class="btn-modal">signup for free</b-button></p><br>
                      </div>
                      <p class="default-text-style"><strong>Billing Cycle</strong></p>
                      <div class="row">
                          <div class="col-md-6">
                              <input type="radio" id="one" :value="false" v-model="prices">
                              <label for="one" class="ml-4">
                                  <p>
                                      Billed Yearly<br>
                                      ${{packageById.price_year}}/year
                                  </p>
                              </label>
                          </div>
                          <div class="col-md-6">
                              <input type="radio" checked id="two" :value="true" v-model="prices">
                              <label for="two" class="ml-4">
                                  <p>
                                      Billed Monthly<br>
                                      ${{packageById.price_month}}/month
                                  </p>
                              </label>
                          </div>
                      </div><br><br>
                      <p class="default-text-style"><strong>Payment Method</strong></p>
                      <div class="row">
                          <div class="col-md-6 col-sm-12">
                              <input type="radio" id="pay" checked="true" class="mt-2" value="stripe">
                              <label for="pay" class="ml-4">
                                  <img src="@/assets/images/logo-visa.svg" alt="">
                              </label><br>
                          </div>
                      </div><br>
                  </div>
                  <div class="col-md-12 horizontal-line-res-1">
                    <hr class="horizontal-line">
                  </div>
                  <div class="col-md-12 col-pad summary-section">
                      <h2 class="title-mobile">Plan Summary</h2>
                      <div class="d-flex justify-content-between">
                          <p class="default-text-style"><strong>{{packageById.name_package}}</strong></p>
                          <p class="default-text-style"><a href="/packages">Change plan</a></p>
                      </div>
                      <div class="prices">
                          <p class="card-text">${{prices ? packageById.price_month : packageById.price_year}}<span> /{{prices ? 'month' : 'year'}}</span></p>
                      </div>
                      <div v-if="!showMore">
                        <p><span class="title-feature default-text-style"><strong>Core Feature</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.storage)}} storage</strong></p>
                        <p class="default-text-style upper422" v-if="packageById.max_uploaded.toLowerCase() === 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.max_uploaded}} file size per upload</strong></p>
                        <p class="default-text-style upper422" v-if="packageById.max_uploaded.toLowerCase() !== 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.max_uploaded}} maximum file size per upload</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Secure file sharing</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=true">Show more</p>
                      </div>
                      <div v-if="showMore">
                        <p><span class="title-feature default-text-style"><strong>Core Feature</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{niceBytes(packageById.basic.storage)}} storage</strong></p>
                        <p class="default-text-style upper422" v-if="packageById.max_uploaded.toLowerCase() === 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.max_uploaded}} file size per upload</strong></p>
                        <p class="default-text-style upper422" v-if="packageById.max_uploaded.toLowerCase() !== 'unlimited'"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.max_uploaded}} maximum file size per upload</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Secure file sharing</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Office document editor</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>Anytime, anywhere access</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>File recovery</strong></p>
                        <p><span class="title-feature default-text-style"><strong>Workgroup</strong></span></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.workgroup.max_workgroup}} maximum workgroup</strong></p>
                        <p class="default-text-style"><img src="@/assets/images/icon/Vector.png" class="logo-vector" alt=""><strong>{{packageById.workgroup.max_participants}} participants</strong></p>
                        <p class="btn blue-text pl-0 default-text-style" @click="showMore=false">Show less</p>
                      </div>
                      <div class="purchases">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">{{packageById.name_package}}</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                          <hr class="horizontal-line">
                          <div class="d-flex justify-content-between">
                              <p class="default-text-style">Total</p>
                              <p class="default-text-style"><strong>${{prices ? packageById.price_month : packageById.price_year}}</strong></p>
                          </div>
                      </div>
                      <div class="col-md-12 horizontal-line-res-2">
                        <hr class="horizontal-line">
                      </div>
                      <div class="purchase-btn-2">
                        <b-form-checkbox
                            id="checkbox-2"
                            v-model="termCondition"
                            name="checkbox-2"
                            class="checkbox-terms"
                            value="accepted"
                            unchecked-value="not_accepted">
                            <p class="term-text default-text-style">I have read and agree to the <span><a href="https://jaybod.com/terms-service" class="text-blue">Terms and Policy.</a></span></p>
                        </b-form-checkbox><br>
                        <div class="d-flex justify-content-start align-items-center">
                            <button :disabled="termCondition == 'not_accepted' ? termCondition : !termCondition" :class="termCondition == 'accepted' ? 'active btn base-btn w-100' : 'btn base-btn w-100'" @click="purchasePackage">
                            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>                     
                            <span class="default-text-style"><span :class="termCondition == 'accepted' ? 'text-white' : ''">Purchase</span></span>
                            </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div> -->
        </div>
    </div>
</template>

<script>
import NavPackage from '../../../components/nav-packages.vue'
import ModalLogin from '../../../components/modalLoginV3.vue'
import ModalRegister from '../../../components/modalRegisterV3.vue'
import ModalConfirmed from '../../../components/modalConfirmed.vue'
import ModalVerify from '../../../components/modalVerifyCode.vue'

export default {
  name: 'Checkout',
  components: {
    NavPackage,
    ModalLogin,
    ModalRegister,
    ModalConfirmed,
    ModalVerify
  },
  data () {
    return {
        termCondition: false,
        loading: false,
        showMore: false
    }
  },
  mounted() {
    // this.$ga.page('/checkout/:id')
    this.$ga.event('checkout-page', 'actionx', 'labelx', 1)
    this.$store.dispatch('packages/getPackageById', this.$route.params.id)
  },
  computed: {
    packageById: {
        cache: false,
        get() {
          let dataId = this.$store.getters["packages/packageById"]
          if(dataId.basic !== undefined) {
            dataId.basic.max_upload = dataId.basic.max_upload.toString()
            if (dataId.basic.max_upload !== 'unlimited') {
              dataId.max_uploaded = this.niceBytes(dataId.basic.max_upload)
            } else {
              dataId.max_uploaded = this.upperCase(dataId.basic.max_upload)
            }
          }
          return dataId
        }
    },
    prices: {
      get: function () {
        return this.$store.getters['packages/selectedPrice']
      },
      set: function (newValue) {
        this.$store.commit('packages/SET_SELECTED_PRICE', newValue);
      }
    },
    isLogin() {
        return this.$store.getters['user/email'] != ""
    },
    emailUser() {
        return this.$store.getters['user/email']
    }
  },
  methods: {
    purchasePackage() {
      if(!this.isLogin) {
        this.$root.$emit('bv::show::modal', 'modal-signin-v3')
      } else {
        const payload = {
          email: this.emailUser,
          price_id: this.prices ? this.packageById.stripe_price_month : this.packageById.stripe_price_year
        }
        const finalPayload = {
          payload,
          is_enterprise: this.packageById.is_enterprise == true ? true : false
        }
        this.$store.dispatch('packages/getCheckout', finalPayload)
      }
    },
    upperCase(str){
      let strIndex = str.split('')
      let arr = [0]
      for(let i = 0; i < arr.length; i++){
          if(strIndex[arr[i]]){
              strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
          }
      }
      return strIndex.join('')
    },
    niceBytes(bytes){
      // const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      // let l = 0, n = parseInt(x, 10) || 0;
      // while(n >= 1024 && ++l){
      //     n = n/1024;
      // }
      // return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return 'n/a';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + ' ' + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
    }
  }
}
</script>

<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    --active: #00AAFF;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #00AAFF;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
.btn-text-white{
    color: #FFFFFF !important;
}
.container-checkout{
    padding: 0px 80px;
}
.container-checkout-mobile{
    padding: 0px 20px;
}
.title-feature{
    font-size: 16px !important;
    opacity: 0.5;
}
.col-pad{
    padding: 60px 20px;
}
.linespack{
  border-left: 2px solid #E9EAEA !important;
  padding: 0 !important;
  margin: 0 !important;
  min-height: 86.5vh;
}
.container-checkout .row h2{
    font-size: 40px;
    font-weight: 600;
    color: #262A2C;
    margin-bottom: 30px;
}
.container-checkout .row .row input{
    margin-right: 20px;
}
.container-checkout .row .row p{
    display: flex;
}
.checkbox-terms{
    margin: 20px 0px;
}
.term-text{
    font-size: 13px;
    font-weight: normal;
}
.prices p{
    font-weight: 600;
    font-size: 37px;
    margin-bottom: 20px;
}
.prices span{
    font-weight: 600;
    font-size: 16px;
    opacity: 0.5;
}
.blue-text{
    color: #00AAFF !important;
}
.logo-vector{
    height: 15px;
    width: 16px;
    margin-right: 20px;
    padding-top: 4px;
    margin-bottom: 7px;
}
.horizontal-line{
    border: none;
    height: 1px;
    background: #dddddd;
    margin-bottom: 20px;
    padding: 0.3px;
}
.purchases{
    margin-top: 50px;
}
.descrip{
    margin-bottom: 0;
}
.btn-modal{
    border: #dddddd !important;
    padding: 0px;
    color: #00AAFF !important;
    background: white;
    margin: 0px 0px 0px !important;
    font-size: 16px !important;
}
.display-mobile{
  display: none;
}
// .purchase-btn-2{
//   display: none;
// }
.horizontal-line-res-2{
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0px;
}
.title-mobile{
  font-size: 32px !important;
  margin-bottom: 30px;
  font-weight: 600 !important;
}
@media (max-width: 806px) {
  .display-mobile{
    display: block;
  }
  .display-webs{
    display: none;
  }
  .upper422{
    display: block;
  }
  .under423{
    display: none;
  }
}
// @media (max-width: 422px) {
//   .upper422{
//     display: none;
//   }
//   .under423{
//     display: block;
//   }
//   .margins-left{
//     margin-left: 36px;
//   }
// }
</style>
